import React from 'react'
import { Route, Routes } from 'react-router-dom';
import Contact from '../components/home/contact/contact';
import Home from '../components/home/home';
import ProductDetails from '../components/productDetails/ProductDetails';
import About from '../components/About/About';
import AllProduct from '../components/AllCetegory/AllProduct';
import BlogDetails from '../components/Blog/BlogDetails';
import BlogPosts from '../components/Blog/BlogPosts';
export default function Allroute() {
    return (
        <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path='/about' element={<About />}></Route>
            <Route path='/blog' element={<BlogPosts />}></Route>
            <Route path='/product/:id' element={<ProductDetails />}></Route>
            <Route path='/allproduct' element={<AllProduct />}></Route>
            <Route path='/blogdetails/:id' element={<BlogDetails />}></Route>
        </Routes>

    )
}
