import React from "react";
import "./footer.css";
import { AiOutlineHome, AiOutlineMobile, AiOutlineMail } from "react-icons/ai";
// import {
//   TiSocialFacebook,
//   TiSocialInstagram,
//   TiSocialLinkedin,
//   TiSocialTwitter,
//   TiSocialYoutube,
// } from "react-icons/ti";
import { NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <div id="footer">
      <div id="columnfirst">
        <b><h3>Excel Security Equipments Pvt Ltd</h3></b>
        <hr></hr>
        <br></br>
        <p>
        Innovation meets security excellence at our core. Your gateway to holistic security and safety in an unpredictable world. We offer more than just security equipment, we provide peace of mind and unmatched protection. Your safety is our paramount commitment.
        </p>
        <br></br>
        
        <p className="footer-icon">
          {/* <TiSocialFacebook></TiSocialFacebook>
          <TiSocialInstagram></TiSocialInstagram>
          <TiSocialLinkedin></TiSocialLinkedin>
          <TiSocialTwitter></TiSocialTwitter>
          <a
            href="#"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TiSocialYoutube></TiSocialYoutube>
          </a> */}
        </p>
        <div className="icons-container">
          <div>
            <AiOutlineHome />
            <p>
            6-3-649/5,
5th Floor, 
Chakkilam House,
Somajiguda,
Hyderabad.500082.
            </p>
          </div>

          <div>
            <AiOutlineMobile />
            <p>
              
              <a href="tel:+919640566696">+91 96-40-56-6696</a>
            </p>
          </div>
          
          <div>
            <AiOutlineMail />
            <p>
              <a href="mailto:info@excelsecurityequipments.com">info@excelsecurityequipments.com</a>
            </p>
          </div>
        </div>
      </div>
      <div id="columnmiddle">
        <b><h3>Quick links</h3></b>
        <hr></hr>
        <br></br>
        <ul>
          <li>
            <NavLink to="/about" style={{ color: "white" }}> About-Us
            </NavLink>
          </li>
          
          <li>
            <NavLink to="/allproduct" style={{ color: "white" }}>Products
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" style={{ color: "white" }}>Contact
            </NavLink>
          </li>
        </ul>
      </div>
      <div id="columnlast">
        <b><h3>Others</h3></b>
        <hr></hr>
        <br></br>
        <ul>
          <li>
            <NavLink to="/" style={{ color: "white" }}>Goto Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/blog" style={{ color: "white" }}>Blog
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}
