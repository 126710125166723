import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  useDisclosure,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import "./ProductDetails.css";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  ChakraProvider,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import Products from "../Products/Products";
import { useParams } from "react-router-dom";
import EnquiryForm from "../PopUpForm/enquiryForm";
function ProductDetails() {
  useEffect(()=>{
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  })
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeTab, setActiveTab] = useState(0);

  const { id } = useParams();
  const product = Products.find((p) => p.id == id);

  if (!product) {
    return <div>Product not found</div>;
  }

  const handleWhatsappButtonClick = () => {
    const isMobile = window.innerWidth < 768;

    if (isMobile) {
      const whatsappLink = `whatsapp://send?phone=+916309832232&text=Hi%20I%20am%20interested%20in%20your%20product%20${encodeURIComponent(
        product.ProductName
      )}%20can%20you%20share%20more%20details%20regarding%20the%20same%3F`;
  
      window.location.href = whatsappLink;
    } else {
      const whatsappWebLink = `https://web.whatsapp.com/send?phone=+916309832232&text=Hi%20I%20am%20interested%20in%20your%20product%20${encodeURIComponent(
        product.ProductName
      )}%20can%20you%20share%20more%20details%20regarding%20the%20same%3F`;
    
     
      window.open(whatsappWebLink, '_blank');
    }
  };
  const fadeInOutVariant = {
    initial: { opacity: 0, translateX: -50 }, // Initial state
    animate: { opacity: 1, translateX: 0 }, // Enter animation
    exit: { opacity: 0, translateX: 50 }, // Exit animation
  };

  const FadeMotion = ({ children, activeTab }) => (
    <AnimatePresence exitBeforeEnter={false}>
      <motion.div
        variants={fadeInOutVariant}
        initial="initial"
        animate="animate"
        exit="exit"
        key={activeTab}
        transition={{ duration: 0.5 }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );

  return (
    <ChakraProvider>
      <div className="product-container">
        <img
          className="product-image"
          src={product.imageUrl}
          alt={product.ProductName}
        />
        <div className="details-container">
          <h2 className="Product-name">{product.ProductName}</h2>
          <p className="Product-price">Price: {product.Price}</p>

          <div className="btn-container">
          <button className="btn fill" onClick={handleWhatsappButtonClick}>Whatsapp Now</button>
            <button className="btn outline" onClick={onOpen}>
              Enquire Now
            </button>
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Enquiry Form</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <form
                    name="enquiry"
                    method="post"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                  >
                    <input type="hidden" name="form-name" value="enquiry" />
                    <EnquiryForm product={product}/>
                  </form>
                </ModalBody>
              </ModalContent>
            </Modal>
          </div>
        </div>
      </div>

      <Tabs onChange={(index) => setActiveTab(index)}>
        <TabList>
          <Tab>Features</Tab>
          <Tab>Specifications</Tab>
          <Tab>Additional Information</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {/* Features */}
            <FadeMotion activeTab={activeTab}>
              <ul className="product-list">
                {product.Features.map((feature, index) => (
                  <li key={index} className="product-list-item">
                    {feature}
                  </li>
                ))}
              </ul>
            </FadeMotion>
          </TabPanel>
          <TabPanel>
            {/* Specifications */}
            <FadeMotion activeTab={activeTab}>
              <ul className="product-list">
                {product.Specifications.map((specification, index) => (
                  <li key={index} className="product-list-item">
                    {specification}
                  </li>
                ))}
              </ul>
            </FadeMotion>
          </TabPanel>
          <TabPanel>
            {/* Additional Information */}
            <FadeMotion activeTab={activeTab}>
              {" "}
              <p className="additional-info">{product.AdditionalInformation}</p>
            </FadeMotion>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </ChakraProvider>
  );
}

export default ProductDetails;
