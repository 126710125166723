import './App.css';
import Footer from './components/footer/footer';
import "./locomotiv.css";
import Navbar from './components/navbar/navbar';
import Allroute from './route/allRout';
import { ChakraProvider } from '@chakra-ui/react';

function App() {
  return (
    <ChakraProvider>
      <div className="App">
        <Navbar />
        <Allroute />
        {/* <Lastdesign/> */}
        <Footer />
        {/* <Popup/> */}
      </div>
    </ChakraProvider>
  );
}

export default App;
