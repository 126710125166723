import React, { useEffect, useState } from "react";
import Swiper from "swiper";
import "./We_Do.css";
import img1 from "./whoweare.png";
const blogSlides = [
  {
    text: "We are a dedicated team of seasoned professionals working together to provide exceptional security solutions. Our specialization lies in delivering cutting-edge security equipment that not only meets but surpasses the highest industry standards. Our comprehensive solutions elevate your security measures, ensuring an unparalleled level of protection for what matters most to you. You can rely on us to be your trusted partner in safeguarding your world.We don't merely provide security equipment, we offer peace of mind and unparalleled protection.Your safety is our top priority and unwavering commitment.",
    image: img1,
  },
];

const We_Do = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [lastSlideIndex, setLastSlideIndex] = useState(0);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    // Add event listener to update isMobile on window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const swiper = new Swiper(".blog-slider", {
      spaceBetween: 30,
      effect: "fade",
    });
    setLastSlideIndex(blogSlides.length - 1);
  }, []);

  return (
    <div className="blog_container">
      <br></br>
      
      {blogSlides.map((slide, index) => (
        <div
          className={`blog-slider ${
            index === lastSlideIndex ? "last-blog-slider" : ""
          }`}
          key={index}
          data-aos="fade-up"
        >
          <div className="blog-slider__wrp swiper-wrapper">
            <div className="blog-slider__item swiper-slide">
              {isMobile ? (
                <>
                  <div className="blog-slider__img">
                    <img src={slide.image} alt="" />
                  </div>
                  <div className="blog-slider__content">
                    <div
                      className="blog-slider__text"
                      data-aos="fade-up"
                      style={{ fontSize: "14px" }}
                    >
                      {slide.text}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {index % 2 === 0 ? (
                    <>
                      <div className="blog-slider__img">
                        <img src={slide.image} alt="" />
                      </div>
                      <div className="blog-slider__content">
                        <div
                          className="blog-slider__title"
                          data-aos="fade-up"
                          style={{
                            fontSize: "250%",
                          }}
                        >
                          {slide.title}
                        </div>
                        <div
                          className="blog-slider__text"
                          data-aos="fade-up"
                          // style={{ fontSize: "17px" }}
                        >
                          {slide.text}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="blog-slider__content">
                        <div
                          className="blog-slider__title"
                          data-aos="fade-up"
                          style={{ fontSize: "250%" }}
                        >
                          {slide.title}
                        </div>
                        <div
                          className="blog-slider__text"
                          data-aos="fade-up"
                          // style={{ fontSize: "17px" }}
                        >
                          {slide.text}
                        </div>
                      </div>
                      <div className="blog-slider__img right">
                        <img src={slide.image} alt="" />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      ))}
      <br></br>
      <br></br>
    </div>
  );
};

export default We_Do;
