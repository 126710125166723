import React, { useEffect } from 'react';
import { SimpleGrid, Card, CardBody, CardFooter, Heading, Text, Button, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Displaycard from '../displaycard/displaycard';
import blogData from './BlogData';

const BlogPosts = () => {
  useEffect(()=>{
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  })
  return (
    <div>
      <Displaycard dynamicText="Blog" />
      <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={4} style={{padding:"2%",margin:"60px 0px"}}>
        {blogData.map((blog, index) => (
          <Card key={index} overflow='hidden' variant='outline' position='relative'>
            <Image
              objectFit='cover'
              maxW='100%'
               // Adjust the image height as needed
              src={blog.blogImage}
              alt={blog.blogName}
            />
            <div
              style={{
                position: 'absolute',
                bottom: '0',
                left: '0',
                width: '100%',
                background: 'rgba(0, 0, 0, 0.75)', // Adjust the background color and opacity as needed
                color: '#fff',
                padding: '10px',
                boxSizing: 'border-box',
              }}
            >
              <Heading size='md'>{blog.blogName}</Heading>
              <Text py='2'>{blog.blogShortDesc}</Text>
              <Link to={`/blogdetails/${index}`}>
                <Button variant='solid' colorScheme='blue'>
                  Read More
                </Button>
              </Link>
            </div>
          </Card>
        ))}
      </SimpleGrid>
    </div>
  );
};

export default BlogPosts;
