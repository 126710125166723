import React from "react";

import Image from "react-image-enlarger";
import car1 from "./cartificate.png"
import car2 from "./cartificate2.png"
import "./Certficate.css"
function SingleSource({ src }) {
  const [zoomed, setZoomed] = React.useState(false);

  return (
    <div   style={{ margin: "0.25rem" }}>
      <Image
        style={{ width: "200px", height: "auto" }} 
        zoomed={zoomed}
        src={src}
        className="cer_imag"
        onClick={() => setZoomed(true)}
        onRequestClose={() => setZoomed(false)}
      />
    </div>
  );
}

const images = [
  car1,car2
];

function Cartificate() {
  return (
   <>
    <div className='section-head'><h4><span>Our </span>Certifications</h4>
        </div>
    <div style={{ display: "flex",justifyContent:"center",flexWrap:"wrap",gap:"20px"}}>
      {images.map(image => (
        <SingleSource key={image} src={image} />
      ))}
    </div>
   </>
  );
}

export default Cartificate;
