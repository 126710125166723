import React, { useEffect, useState } from "react";
import ProductList from "./ProductList";
import axios from "axios";
import ReactPaginate from 'react-paginate';
import "./Allproduct.css";
import productData from '../Products/Products';
import Displaycard from "../displaycard/displaycard";
import {
  Select,
  useDisclosure,
  Button,
  ChakraProvider,
  Input, InputGroup, InputRightElement,
} from "@chakra-ui/react";
import { FaSearch } from 'react-icons/fa';
export default function AllProduct() {
  const [products, setProducts] = useState([]);
  const [filter, setFilter] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [_length, setLength] = useState("");
  const [_width, setWidth] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [showsort, setShowsort] = useState("")
  const [main, setMain] = useState("")
  const [loading, setLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState('');
  window.scrollTo(0, 0);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchData,setSearchData]=useState(null)  

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchTerm(query);

    const filteredResults = productData.filter(item =>
      item.ProductName.toLowerCase().includes(query.toLowerCase())
    );
    setSearchData(filteredResults)
    const offset = (currentPage - 1) * 8;
    const newData = filteredResults.slice(offset, offset + 8);
    setProducts(newData);
    setTotalPages(Math.ceil(filteredResults.length / 8));
    
  };
  const handleSortChange = (e) => {
    const sortingOrder = e.target.value;
    if(searchData){
      console.log(searchData,"sear")
      const sortedData = sortProducts(searchData, sortingOrder);
      setShowsort(sortedData)
      
      const offset = (currentPage - 1) * 8;
      const newData = sortedData.slice(offset, offset + 8);
      setProducts(newData);
      
      setTotalPages(Math.ceil(sortedData.length / 8));
    setSortOrder(sortingOrder);
    }
    else{
      
      const sortedData = sortProducts(productData, sortingOrder);
      setShowsort(sortedData)
      const offset = (currentPage - 1) * 8;
      const newData = sortedData.slice(offset, offset + 8);
      setProducts(newData);
      setTotalPages(Math.ceil(sortedData.length / 8));
    setSortOrder(sortingOrder);
    }
  };
  console.log(products)
  const sortProducts = (data, order) => {
    const sortedData = [...data]; // Create a new array to avoid mutating the original data

    if (order === 'asc') {
      sortedData.sort((a, b) => parseFloat(a.Price.replace(/,/g, '')) - parseFloat(b.Price.replace(/,/g, '')));
      
    } else {
      sortedData.sort((a, b) => parseFloat(b.Price.replace(/,/g, '')) - parseFloat(a.Price.replace(/,/g, '')));
    }

    return sortedData; // Return the sorted data instead of directly setting state
  };



  useEffect(() => {
    const fetchData = async () => {
      // Fetch your product data and set it to productData state
      // For example:
      // const data = await fetchProducts(); // Fetch your products
      // setProductData(data); // Set the fetched data to productData state

      // Sample data setting (replace this with your actual data)
      const sampleData = productData
      setProducts(sampleData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const offset = (currentPage - 1) * 8;
    if(searchData && sortOrder){
      const newData = showsort.slice(offset, offset + 8);
      setProducts(newData);
      setTotalPages(Math.ceil(showsort.length / 8));
    }
    else if(searchData){
      
      
      const newData = searchData.slice(offset, offset + 8);
    setProducts(newData);
    setTotalPages(Math.ceil(searchData.length / 8));
    
    }
    else if(sortOrder){
    
      const newData = showsort.slice(offset, offset + 8);
      setProducts(newData);
      setTotalPages(Math.ceil(showsort.length / 8));
    }
    else{
      
      const newData = productData.slice(offset, offset + 8);
    setProducts(newData);
    setTotalPages(Math.ceil(productData.length / 8));
    }
  }, [currentPage, productData]);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };
  return (
    <>
      <ChakraProvider>
      <Displaycard dynamicText="Products"/>
        <div className="pro-main">
          <div className="Flex">
            <div
              className="Flex"
              style={{ width: "80%", justifyContent: "space-between", gap: '10px', padding: '20px 0px' }}
            >
              <div className="sort-bt">
                <Select
                  id="size-btn"
                  style={{
                    background: 'linear-gradient(to right, rgba(0, 174, 255, 0.5), rgba(106, 13, 173, 0.5))',
                    outline: "none",
                    boxShadow: "none",
                    // Hides the focus outline
                  }}
                  onChange={(e) => {
                    handleSortChange(e)
                  }}
                >
                  <option value="" disabled selected>Sort By Price</option>
                  <option value='asc'>Low To High</option>
                  <option value='desc'>High To Low</option>
                </Select>
              </div>
              <div>
                <InputGroup>
                  <Input type="search" placeholder="Search..." value={searchTerm}
        onChange={handleSearch}/>
                  <InputRightElement>
                    <FaSearch color="rgba(106, 13, 173, 0.5)" />
                  </InputRightElement>
                </InputGroup>
              </div>

            </div>
          </div>
          {products.length > 0 ? (
            <div className="product-body"><ProductList products={products} /></div>
          ) : (
            <h1>Data Not Found</h1>
          )}
          <br />
          <div className="Flex" style={{ padding: '30px 0px' }}>
            <ReactPaginate
              pageCount={totalPages}
              pageRangeDisplayed={2} // Adjust this value to control the number of visible page numbers
              marginPagesDisplayed={0} // Set this to 0 to hide extra pages at the start and end
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
          <br />
        </div>
      </ChakraProvider>
    </>
  );
}