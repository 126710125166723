import React from 'react';
import './featureSection.css';
import { FaShieldAlt, FaTools, FaTruck, FaHandshake, FaMapMarkedAlt, FaCoins } from 'react-icons/fa';
import { FaRegLifeRing } from 'react-icons/fa';
function FeatureSection() {
  return (
    <div className="feat pt-5 pb-5 feature-main-div" >
      <div className="container">
        <div className="row">
          <div className="section-head col-sm-12 "  style={{margin:"0px 0px 60px 0px"}}>
            <h4><span>Why Choose</span> Us?</h4>
            <p>As pioneers in the field, we set the industry benchmark for security excellence. Excel Security Equipments is more than just a provider; we're your trusted partner in safeguarding what matters most to you. Experience the future of security with us, where innovation meets assurance.
            </p>
          </div>
          <div className="col-lg-6 col-sm-6">
            <div className="item">
              <span className="icon feature_box_col_one"><FaShieldAlt />
              </span>
              <h6>Dedication to Security</h6>
              <p>We take immense pride in our unwavering dedication to the security industry. Our focus is crystal clear, and our passion is driven by a commitment to safeguarding what matters most to you.</p>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6">
            <div className="item">
              <span className="icon feature_box_col_two">
                <FaTools />
              </span>
              <h6>Expert Technical Team</h6>
              <p>At Excel Security Equipments, we understand the critical importance of expertise. Our highly qualified technical team is the heart of our success, ensuring that you receive the most reliable and effective security solutions.</p>
            </div>
          </div>
          {/* Repeat the same structure for other columns */}
          <div className="col-lg-6 col-sm-6">
            <div className="item">
              <span className="icon feature_box_col_three">
                <FaTruck />
              </span>
              <h6>Speedy Delivery</h6>
              <p>We value your time, recognizing the urgency of your security needs. Our streamlined processes guarantee swift delivery, allowing you to fortify your surroundings without unnecessary delays.</p>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6">
            <div className="item">
              <span className="icon feature_box_col_four">
                <i className="fa fa-database"><FaHandshake/></i>
              </span>
              <h6>Unwavering Reliability</h6>
              <p>Reliability is the cornerstone of our service. From product recommendations to post-purchase support, our commitment to your security is steadfast and dependable.</p>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6">
            <div className="item">
              <span className="icon feature_box_col_five">
                <i className="fa fa-upload"><FaMapMarkedAlt/></i>
              </span>
              <h6>Nationwide Presence</h6>
              <p>Excel Security Equipments has a widespread presence across India. Our national reach ensures that top-notch security solutions are accessible to everyone, everywhere.</p>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6">
            <div className="item">
              <span className="icon feature_box_col_six">
                <i className="fa fa-camera"><FaCoins/></i>
              </span>
              <h6>Affordable cost</h6>
              <p>We believe that security should be accessible to everyone. That's why we offer cost-effective solutions without compromising on quality. Experience top-notch security at a price that suits your budget.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeatureSection;
