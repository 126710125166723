import { useState } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import { RxCross1 } from "react-icons/rx";
import { GiHamburgerMenu } from "react-icons/gi";
import logo from "./logo1.png";
const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };
  return (
    <nav className="navbar">
      <div className="container">
      <div className="menu-icon" onClick={handleShowNavbar}>
          {showNavbar ? <RxCross1 /> : <GiHamburgerMenu />}
        </div>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Excel Security Systems" />
          </Link>
        </div>
        
        <div className={`nav-elements ${showNavbar && "active"}`}>
          <div>
            <div className="route">
              <Link to="/" onClick={() => setShowNavbar(false)}>
                Home
              </Link>
            </div>
            <div className="route">
              <Link to="/about" onClick={() => setShowNavbar(false)}>
                About-Us
              </Link>
            </div>
            <div className="route">
              <Link to="/blog" onClick={() => setShowNavbar(false)}>
                Blog
              </Link>
            </div>
            <div className="route">
              <Link to="/allproduct" onClick={() => setShowNavbar(false)}>
                Products
              </Link>
            </div>
            <div className="route">
              <Link to="/contact" onClick={() => setShowNavbar(false)}>
                Contact
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
