import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Card, CardBody, Stack, Heading, Text, Divider } from '@chakra-ui/react';
import blogData from './BlogData'; // Ensure this path is correct

function BlogDetails() {
  const { id } = useParams();
  const blog = blogData.find((p, index) => index.toString() === id);
useEffect(()=>{
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
})
  if (!blog) {
    return <div>Blog not found</div>;
  }

  const imageStyle = {
    backgroundImage: `url(${blog.blogImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '400px', 
    // Adjust the height as needed
  };

  const overlayStyle = {
    position: 'relative',
    // height: '100%',
    background: 'rgba(0, 0, 0, 0.7)', // Black with 70% opacity (adjust as needed)
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    padding: '20px', // Padding for the text
    boxSizing: 'border-box',
  };

  return (
    <div>
      <Box maxW='800px' mx='auto' mt='6'>
        <Card>
          <Box style={imageStyle}>
            <div style={overlayStyle}>
              <CardBody py='6' textAlign='center'>
                <Stack spacing='3' align='center'>
                  <Heading size='lg' color='white'>
                    {blog.blogName}
                  </Heading>
                </Stack>
              </CardBody>
            </div>
          </Box>
          <CardBody py='6'>
            <Divider my='4' />
            <Text dangerouslySetInnerHTML={{ __html: blog.blogDetailed }} />
          </CardBody>
        </Card>
      </Box>
    </div>
  );
}

export default BlogDetails;
