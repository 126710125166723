import React, { useEffect } from 'react';
import imgage from "./we.png"
import aboout1 from "./about1.jpeg"
import aboout2 from "./a.jpeg"
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  ChakraProvider,
} from "@chakra-ui/react";
import './about.css'
import DisplayCard from '../displaycard/displaycard';
function About() {
  useEffect(()=>{
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  })  
  return (
    <div>
        <DisplayCard dynamicText="About-Us"/>
      <section>
        <div className='custency' >
          <div className='About-Us-content first'>
            <Tabs variant="soft-rounded" colorScheme="teal" p={0}>
              <div>
                <h2 className='h2'>Mr. C. Bhaskar Reddy</h2>
                <span style={{ fontSize: "1.3rem", fontFamily: "Marcellus, serif", fontWeight: "normal", fontStyle: "italic", marginTop: "-10px" }}>Managing Director and Chairman, Excel Group</span>
                <p style={{ color: "white",marginBottom:'10px' }}>Over 30 years of Experience in the security industry.</p>
              </div>
              <TabList>
                <Tab>Roles</Tab>
                <Tab>Contributions</Tab>
                <Tab>Memberships</Tab>
              </TabList>
              <TabPanels p={0}>
                <TabPanel>
                  <div>
                    {/* <h2 className='h2'>Roles:</h2> */}
                    <div style={{ listStyle: "inside", color: "white" }}>
                      <p>Former President, Association of Private Security Agencies (APSA) in Telangana and Andhra Pradesh (four terms).</p>
                      <p>Past President, International Institute of Security and Safety Management (IISSM).</p>
                      <p>Past President, CAPSI Chapter.</p>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div>
                    {/* <h2 className='h2'>Contributions:</h2> */}
                    <div style={{ listStyle: "inside", color: "white" }}>
                      <p>Led numerous Security Workshops.</p>                   
                      <p>Instrumental in shaping Excel Group's success.</p>
                      <p>Committed to advancing professional standards in security.</p>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div>
                    {/* <h2 className='h2'>Memberships:</h2> */}
                    <div style={{  listStyle: "inside", color: "white" }}>
                      <p>Hyderabad City Security Council.</p>
                      <p>Telangana Rifle Association, Hyderabad.</p>
                      <p>Association of Private Security Agencies (TS & AP).</p>
                      <p>International Institute of Security and Safety Management (Delhi).</p>
                      <p>Asian Professional Security Association (Delhi).</p>
                      <p>American Society for Industrial Security.</p>
                      <p>World Association of Detectives Inc (USA).</p>
                    </div>
                  </div>
                </TabPanel>
              </TabPanels>
            </Tabs>


            <div>
              <img src={aboout1} />
            </div>
          </div>
        </div>


      </section>
      <section data-scroll-section>
        <div className='custency'>
          <div className='About-Us-content'>
            <div>
              <img src={aboout2} />
            </div>
            <div className='content'>
              <div >
                <h2 className='h2'>Mr. C. Tejdeep Reddy</h2>
              </div>
              <span style={{ fontSize: "1.3rem", fontFamily: "Marcellus,serif", fontWeight: "normal", fontStyle: "italic", marginTop: "-10px" }}>Director, Excel Security Equipments</span>
              <br/>
              <p style={{fontStyle:"18px",color:"white"}}>I am delighted to extend a warm welcome to you on behalf of Excel Security Equipments. As the proud director of this distinguished establishment, I am thrilled to have you explore the digital space we have meticulously crafted.</p><br></br>
              <p style={{fontSize:"18px",color:"white"}}>My commitment to providing quality service excellence has been the driving force behind our achievements. I invite you to explore our website, where you will discover innovative solutions, unparalleled expertise, and personalized services that define us. Whether you are a long-time partner or a newcomer seeking the best in standard security equipment, we have tailored this platform with your needs in mind.</p>
              <br></br>
              <p style={{fontSize:"18px",color:"white"}}>Feel free to navigate through our products and services section to gain insights into what sets us apart. I am dedicated to ensuring that your experience with us is not just informative but also inspiring.</p>
              <br></br>
              <p style={{fontStyle:"18px",color:"white"}}>Thank you for considering Excel Security Equipments as your trusted partner. I am eager to embark on this digital journey with you and am confident that you will find value, inspiration, and compelling reasons to choose us.</p>
            </div>

          </div>
        </div>


      </section>
    </div>


  )
}

export default About
