import Slider from "react-slick"
import logo1 from "./Untitled-1.jpg"
import logo2 from "./fg.jpg"
import logo3 from "./shill.jpg"
import logo4 from "./tata.jpg"
import logo5 from "./bong.jpg"
import logo6 from "./namd.jpg"
import logo7 from "./lockhead.jpg"
import logo8 from "./tatadvance.jpg"
import logo9 from "./rs.jpg"
import logo10 from "./aiie.jpg"
import './Clients.css'

function Clients() {
  const client = [{
    image: logo1
  },
  {
    image: logo2
  },
  {
    image: logo3
  },
  {
    image: logo4
  },
  {
    image: logo5
  },
  {
    image: logo6
  },
  {
    image: logo7
  },
  {
    image: logo8
  },
  {
    image: logo9
  },
  {
    image: logo10
  }];

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <div data-scroll-section style={{ width: "90%", margin: "auto" }}>
        <div className='section-head'><h4><span>Our Major </span>Clients</h4>
        </div>
        <Slider {...sliderSettings} className="product-slider" >
          {client.map((product,i) => (
            <div key={i}>
              <div className="client-product_img">
                <img src={product.image} />
              </div>
            </div>
          ))}
        </Slider>
      </div>

    </div>
  )
}

export default Clients
