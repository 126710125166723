import React, { useState, useEffect } from 'react';
import discard from "./discard2.png";
import displaycardMobile from './displaycardMobile.jpg';
import "./displaycard.css"

function DisplayCard({ dynamicText }) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="container_dc">
            <img className="image_dc" src={isMobile ? discard : discard} alt={isMobile ? "Mobile Image" : "Image"} />
            <div className="text_dc">
                <button style={{fontSize:isMobile?"1rem":"2rem"}}>{dynamicText}</button>
            </div>
        </div>
    );
}

export default DisplayCard;
