import React from 'react';
import { Modal, ModalOverlay, useDisclosure, ModalContent, ModalHeader, ModalCloseButton, ModalBody } from '@chakra-ui/react';
import EnquiryForm from '../PopUpForm/enquiryForm';
import { Link } from 'react-router-dom';
const AllProductCard = ({ product }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <div className="all-product-card" data-aos="zoom-in-up" data-aos-offset="120">


      <img className="all-product-image" loading="lazy" src={product.imageUrl} alt={product.name} draggable="false" />
      <div className="all-product-details">
        <p className="all-product-name">{product.ProductName}</p>
        <p className="product-description"> <b>Price:₹</b>{product.Price}</p>
      </div>

      <div className="button-wrapper">
        <Link to={`/product/${product.id}`}> <button className="btn outline">Details</button></Link>
        <button className="btn fill" onClick={onOpen}>Enquiry</button>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Enquiry Form</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <EnquiryForm product={product} onClose={onClose} />
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>
    </div>
  );
};

export default AllProductCard;
