import React from 'react';
import { Link } from 'react-router-dom';
import AllProductCard from './AllProductCard';
import './AllproductCard.css';
const ProductList = ({ products }) => {
  return (
    <>

      <div className="all-product-list">
        {products.map((product) => (
          
            <AllProductCard key={product.id} product={product} />
        ))}
      </div>

    </>
  );
};

export default ProductList;
