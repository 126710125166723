import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './home.css';
import Banner from '../HomeBanner/Banner';
import ProductCard1 from '../productCard/productCard1';
import We_Do from './WathWeDo/We_Do';
import FeatureSection from './FutureSection/featureSection';
import Clients from './Clients/Clients';

import productData from '../Products/Products';
import AllProductCard from '../AllCetegory/AllProductCard';
import { Link } from 'react-router-dom';
import Cartificate from '../Cartificate/Cartificate';


export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top-left corner of the page
  }, []);
  // console.log(productData);
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div data-scroll-container className='bg-gray'>
      <Banner />



      <We_Do />

      <div data-scroll-section style={{ overflow: 'hidden' }}>
        <div className='section-head'>

          <h4> <span>Best Selling{" "}</span>Products</h4>
        </div>
        <Slider {...settings} className="product-slider" >
          {productData.map((product) => (
            <AllProductCard key={product.id} product={product} />
          ))}
        </Slider>
      </div>
      <div style={{display:'flex',fontSize:'1.5rem',justifyContent:'end',padding:'15px',color:'rgba(106, 13, 173, 0.5)'}}>
        <Link to={'./allproduct'}><b>View All &#8594; </b></Link>
      </div>

      <FeatureSection />


            <Clients />
      <br />
   <Cartificate/>
    </div>
  )
}

