


import "./Banner.css"

import vid from "./banner.mp4"
export default function Banner (){
  return (
<>

    <div class="home_banner">
    <span style={{ background: "linear-gradient(to right, rgba(0, 174, 255, 0.5), rgba(106, 13, 173, 0.5))" }}>
<video src={vid}  class="background-video" autoPlay muted loop />
     </span>
               
    <div className="banner_text">
      <h1>Excel Security Equipments Pvt Ltd</h1>
      <br/>
      <h4>Your Premier Destination for Comprehensive Security Solutions
</h4>
    </div>
  </div>

</>
  )}