import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import { useFormik } from "formik";
import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Textarea,
    useToast,
    Spinner
} from "@chakra-ui/react";
import * as Yup from "yup";

const EnquiryForm = ({ product, onClose }) => {
    const toast = useToast();
    const [isSending, setIsSending] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top-left corner of the page
    }, []);
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Required"),
        email: Yup.string().email("Invalid email address").required("Required"),
        mobile: Yup.string().required("Required"),
        message: Yup.string().required("Required"),
    });
    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            mobile: "",
            message: "",
        },
        validationSchema,
        onSubmit: (values, actions) => {
            setIsSending(true); // Start the sending animation
            const templateParams = {
                from_name: values.name,
                from_email: values.email,
                to_email: 'webperfection2023@gmail.com', // Replace with your recipient email address
                phone: values.phone,
                message: values.message,
            };
            emailjs.send(
                'service_l6e3ivf', // Replace with your EmailJS service ID
                'template_4du3a37', // Replace with your EmailJS template ID
                templateParams,
                'KUi3Mo7k1zt_caVSt' // Replace with your EmailJS user ID (optional)
            )
                .then(
                    (result) => {
                        console.log("Email successfully sent!", result.text);
                        actions.resetForm();
                        onClose();
                        toast({
                            title: "Success!",
                            description: "Your message was sent, thank you!",
                            status: "success",
                            duration: 5000,
                            isClosable: true,
                        });
                    },
                    (error) => {
                        toast({
                            title: "Error!",
                            description: "Failed to send the message. Please try again.",
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                        });
                    }
                ).finally(() => {
                    setIsSending(false); // Stop the sending animation after email sending (success or error)
                });;
        },
    });


    return (
        <form onSubmit={formik.handleSubmit} id="contactForm">
            <FormControl id="name" isInvalid={formik.errors.name && formik.touched.name}>
                <FormLabel>Full Name</FormLabel>
                <Input
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                />
                <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
            </FormControl>

            <FormControl id="email" isInvalid={formik.errors.email && formik.touched.email}>
                <FormLabel>Email Address</FormLabel>
                <Input
                    type="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                />
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>

            <FormControl id="mobile" isInvalid={formik.errors.mobile && formik.touched.mobile}>
                <FormLabel>Mobile Number</FormLabel>
                <Input
                    type="text"
                    name="mobile"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mobile}
                />
                <FormErrorMessage>{formik.errors.mobile}</FormErrorMessage>
            </FormControl>

            <FormControl id="message" isInvalid={formik.errors.message && formik.touched.message}>
                <FormLabel>Message</FormLabel>
                <Textarea
                    name="message"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                />
                <FormErrorMessage>{formik.errors.message}</FormErrorMessage>
            </FormControl>

            <Button
                type="submit"
                mt={4}
                colorScheme="blue"
                position="relative"
                display="inline-flex" // Set display to inline-flex for proper centering
                alignItems="center" // Align items to center
            >
                {isSending ? (
                    <>
                        <Spinner
                            size="sm"
                            color="white"
                            mr={2} // Add margin to separate spinner from text
                        />
                        <span>Sending...</span>
                    </>
                ) : (
                    "Send Message"
                )}
            </Button>

        </form>
    );
};

export default EnquiryForm;
