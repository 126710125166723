import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import { useFormik } from "formik";
import Displaycard from "../../displaycard/displaycard";
import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Textarea,
    useToast,
    Spinner
} from "@chakra-ui/react";
import * as Yup from "yup";
import "./contact.css";
import contactphoto from "./contact_excel.jpeg";
function Contact() {
    const toast = useToast();
    const [isSending, setIsSending] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top-left corner of the page
    }, []);
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Required"),
        email: Yup.string().email("Invalid email address").required("Required"),
        mobile: Yup.string().required("Required"),
        message: Yup.string().required("Required"),
    });
    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            mobile: "",
            message: "",
        },
        validationSchema,
        onSubmit: (values, actions) => {
            setIsSending(true); // Start the sending animation
            const templateParams = {
                from_name: values.name,
                from_email: values.email,
                to_email: 'webperfection2023@gmail.com', // Replace with your recipient email address
                phone: values.phone,
                message: values.message,
            };
            emailjs.send(
                'service_l6e3ivf', // Replace with your EmailJS service ID
                'template_4du3a37', // Replace with your EmailJS template ID
                templateParams,
                'KUi3Mo7k1zt_caVSt' // Replace with your EmailJS user ID (optional)
            )
                .then(
                    (result) => {
                        console.log("Email successfully sent!", result.text);
                        actions.resetForm();
                        toast({
                            title: "Success!",
                            description: "Your message was sent, thank you!",
                            status: "success",
                            duration: 5000,
                            isClosable: true,
                        });
                    },
                    (error) => {
                        toast({
                            title: "Error!",
                            description: "Failed to send the message. Please try again.",
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                        });
                    }
                ).finally(() => {
                    setIsSending(false); // Stop the sending animation after email sending (success or error)
                });;
        },
    });
    return (
        <section style={{ overflow: 'hidden', padding: '0px' }}>
            <Displaycard dynamicText="Contact"/>
            <div>
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div >
                            <div className="row no-gutters" style={{ background: '#fff' }}>
                                <div className="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch">
                                    <div className="contact-wrap p-md-5 p-4" style={{ height: 'fit-content', margin: 'auto', width: '95%' }}>
                                        <h2
                                            className="mb-4"
                                            style={{ color: "rgba(106, 13, 173, 0.5)", textAlign: "center", fontWeight: "bold", fontSize: "24px" }}
                                        >
                                            Let's Get in Touch
                                        </h2>
                                        <div id="form-message-warning" className="mb-4"></div>
                                        <div id="form-message-success" className="mb-4">
                                            Your message was sent, thank you!
                                        </div>
                                        <form onSubmit={formik.handleSubmit} id="contactForm">
                                            <FormControl id="name" isInvalid={formik.errors.name && formik.touched.name}>
                                                <FormLabel>Full Name</FormLabel>
                                                <Input
                                                    type="text"
                                                    name="name"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.name}
                                                />
                                                <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                                            </FormControl>

                                            <FormControl id="email" isInvalid={formik.errors.email && formik.touched.email}>
                                                <FormLabel>Email Address</FormLabel>
                                                <Input
                                                    type="email"
                                                    name="email"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.email}
                                                />
                                                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                                            </FormControl>

                                            <FormControl id="mobile" isInvalid={formik.errors.mobile && formik.touched.mobile}>
                                                <FormLabel>Mobile Number</FormLabel>
                                                <Input
                                                    type="text"
                                                    name="mobile"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.mobile}
                                                />
                                                <FormErrorMessage>{formik.errors.mobile}</FormErrorMessage>
                                            </FormControl>

                                            <FormControl id="message" isInvalid={formik.errors.message && formik.touched.message}>
                                                <FormLabel>Message</FormLabel>
                                                <Textarea
                                                    name="message"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.message}
                                                />
                                                <FormErrorMessage>{formik.errors.message}</FormErrorMessage>
                                            </FormControl>

                                            <Button
                                                type="submit"
                                                mt={4}
                                                colorScheme="blue"
                                                position="relative"
                                                display="inline-flex" // Set display to inline-flex for proper centering
                                                alignItems="center" // Align items to center
                                            >
                                                {isSending ? (
                                                    <>
                                                        <Spinner
                                                            size="sm"
                                                            color="white"
                                                            mr={2} // Add margin to separate spinner from text
                                                        />
                                                        <span>Sending...</span>
                                                    </>
                                                ) : (
                                                    "Send Message"
                                                )}
                                            </Button>

                                        </form>
                                    </div>
                                </div>
                                <div className="info-wrap col-lg-4 col-md-5 align-items-stretch d-flex justify-content-center p-4">
                                    {/* Embed Google Map */}
                                    <img
                                       
                                        width="95%"
                                        height="90%"
                                                                               style={{ border: 0, borderRadius: '10px', margin: 'auto' }}
                                        src={contactphoto}
                                                                           />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-4">
                                    {/* Embed Google Map */}
                                    <iframe
                                        title="Location"
                                        width="95%"
                                        height="90%"
                                        frameBorder="0"
                                        style={{ border: 0, borderRadius: '10px', margin: 'auto' }}
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.8575870153786!2d78.4581487!3d17.418620800000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb97e411d5c7b7%3A0x330b6bd2688097ca!2sChakkilam%20House!5e0!3m2!1sen!2sin!4v1704653433931!5m2!1sen!2sin"
                                        allowFullScreen
                                    ></iframe>

            </div>
        </section>
    );
}

export default Contact;
