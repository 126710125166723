
import img1 from './1.png';
import img2 from './2.png';
import img3 from './3.png';
import img4 from './4.png';
import img5 from './5.png';
import img6 from './6.png';
import img7 from './7.png';
import img8 from './8.png';
import img9 from './9.png';
import img10 from './10.png';
import img11 from './11.png';
import img12 from './12.png';
import img13 from './13.png';
import img14 from './14.png';
import img15 from './15.png';
import img16 from './16.png';
import img17 from './17.png';
import img18 from './18.png';
import img19 from './19.png';
import img20 from './20.png';
import img21 from './21.png';
import img22 from './22.png';
import img23 from './23.png';
import img24 from './24.png';
import img25 from './25.png';
import img26 from './26.png';
import img27 from './27.png';
import img28 from './28.png';
import img29 from './29.png';
import img30 from './30.png';

const Products=[
    {"id":"1",
        "ProductName": "Door Frame Metal Detector (MODEL SLIM-002) (SINGLE ZONE)",
        "Price": "35,000",
        "Features": [
          "Micro Controller Based Walk Through Door Frame Metal Detector, Model: DFMD-002(slim)",
          "Detects both Ferrous and Non-Ferrous Alloys",
          "Auto Set Model, easy to install and user-friendly",
          "A.B.S Laminated side panel and control module",
          "Self Balancing against any change in Temperature, Humidity, and Proximity of metal in the surrounding",
          "In-built IR sensors to avoid external unwanted detection and false alarms",
          "Walk / Stop Display to regulate traffic flow",
          "6 Digit Traffic In/Out counter",
          "Selectable Sensitivity Level 0-9",
          "Adjustable Volume Control",
          "10 Level LED Bar Graph Display",
          "3 Level LED Detection Display"
        ],
        "Specifications": [
          "Passage clearance - 1900 x 300 x 685 mm",
          "Archway Assemble - 2110 x 300 x 790 mm",
          "Weight – 40 kg",
          "Power – 230v AC 50hz",
          "Operating Temperature – -10°C to 55°C",
          "Indication – 3 LED’s for Detection",
          "10 Digit LED Bar Graph",
          "Walk / Stop Indicator",
          "6 Digit In/Out Counter",
          "Power ON LED indicator",
          "Battery Back-up as per customer requirement",
          "Audio Interface"
        ],
        "AdditionalInformation": "Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img1
      },
      {"id":"2",
        "ProductName": "Door Frame Metal Detector (MODEL UB 500) (6 ZONE)",
        "Price": "70,000",
        "Features": [
          "Program Self-diagnostic when power on, automatically checking left and right door panels.",
          "Adopt advanced digital signal processing control, replacing traditional analog signal control, for better stability.",
          "LED light alarm: LED bar graph on the left and right door frame for visible alarm simultaneously to ascertain the layout of metal articles.",
          "Pass count up to 1 Lakh"
        ],
        "Specifications": [
          "Zone Configuration: 6 Zones"
        ],
        "AdditionalInformation": "Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img2
      },
      {"id":"3",
        "ProductName": "Door Frame Metal Detector (MODEL UB 500) (6 ZONE)",
        "Price": "75,000",
        "Features": [
          "Program Self-diagnostic when powered on, automatically checking the left and right door panels.",
          "Adopt advanced digital signal processing control, replacing traditional analog signal control for better stability.",
          "LED light alarm: LED bar graph on the left and right door frame for visible alarm simultaneously to ascertain the layout of metal articles.",
          "Battery backup, 4 hours.",
          "Pass count up to 1 Lakh"
        ],
        "Specifications": [
          "Zone Configuration: 6 Zones"
        ],
        "AdditionalInformation": "Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img3
      },
      {"id":"4",
        "ProductName": "Door Frame Metal Detector (MODEL UM600) (18 ZONE) Waterproof Outdoor Unit",
        "Price": "85,800",
        "Features": [
          "Six mutual over-lapping detecting zones, simultaneous alarm from multi-zones.",
          "Small LCD screen with English operating software for easy operation.",
          "Sensitivity adjustable: Each zone has 100 sensitivity levels.",
          "Sound & LED alarm: Both side LED indicators easily show where the metal is detected.",
          "Automatically counts passengers and alarm times.",
          "Alarm strength indicator on the control panel.",
          "Password protection, only authorized personnel can operate.",
          "Using high-density fireproof material.",
          "Modularization design for easy maintenance and replacement.",
          "Sensitivity Test piece: One yuan Coin, omnidirectional detection.",
          "4-8 hours backup battery (Option)",
          "Pass count up to 10 Lakh",
          "Applications are Exhibition center, Electronic Factory, Bank, Prison, Government office, Hotel, etc."
        ],
        "Specifications": [
          "Power Supply: AC85V-264V",
          "Working temperature: -20℃ to +50℃",
          "Working Frequency: 4KHZ—8KHZ",
          "Standard External size: 2200mm(H) x 800mm(W) x 580mm(D)",
          "Standard Internal size: 2010mm(H) x 700mm(W) x 500mm(D)",
          "Package size for door panels: 2260 x 650 x 260mm *1ctn",
          "Package size for control unit: 780 x 390 x 250mm *1ctn",
          "Gross weight: 70KG"
        ],
        "AdditionalInformation": "Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img4
      },
      {"id":"5",
        "ProductName": "Door Frame Metal Detector (MODEL XYT2101-S) (18 ZONES)",
        "Price": "85,800",
        "Features": [
          "Detecting Zones: 18 detecting zones.",
          "Separate left and right side detection.",
          "Simultaneous alarm from multi-zones.",
          "LED light alarm: LED bar graph on the left and right door frame for visible alarm simultaneously to ascertain the layout of metal articles.",
          "LCD screen with Infrared remote controller, English program for easy operation.",
          "Battery backup, 4 hours."
        ],
        "Specifications": [],
        "AdditionalInformation": "Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img6
      },
      {"id":"6",
        "ProductName": "Door Frame Metal Detector (MODEL XYT2101-LCD) (24 ZONES)",
        "Price": "1,72,700",
        "Features": [
          "LED light alarm: LED bar graph on the left and right door frame for visible alarm simultaneously to ascertain the layout of metal articles.",
          "Lock and Password protection, only authorized personnel can operate.",
          "Anti-interference function: Uses advanced interactive transmitting and receiving to avoid interference, with a frequency range from 4K-8K to avoid frequency interference.",
          "PVC material and Control unit on the top: Weatherproof, moisture-proof, and cooler.",
          "Battery Backup: Not less than 4 hours."
        ],
        "Specifications": [
          "Zone Configuration: 24 Zones"
        ],
        "AdditionalInformation": "Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img5
      },
      {"id":"7",
        "ProductName": "Door Frame Metal Detector (MODEL XYT2101-B) (24 ZONES)",
        "Price": "1,99,100",
        "Features": [
          "Weatherproof design",
          "Zoning Management: The detection zone can be adjusted into 8, 16, or 24 zones.",
          "Battery Backup: Not less than 4 hours.",
          "Detects all metals, both ferrous and non-ferrous.",
          "Highly sensitive and auto-tunes to every application.",
          "Working temperature: -10°C to +50°C",
          "Humidity: 0-95% RH"
        ],
        "Specifications": [
          "Zone Configuration: 24 Zones"
        ],
        "AdditionalInformation": "Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img7
      },
      {"id":"8",
        "ProductName": "Hand Held Metal Detector (MODEL MD-3003b)",
        "Price": "3,000",
        "Features": [
          "Zk-300 is a high-performance hand-held metal detector.",
          "Designed to meet the exact requirements of the security industry."
        ],
        "Specifications": [],
        "AdditionalInformation": "Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img8
      },
      {"id":"9",
        "ProductName": "Hand Held Metal Detector (MODEL Met scan MD-003 SLIM)",
        "Price": "3,500",
        "Features": [
          "Designed for law enforcement security and policing.",
          "Detects all metals, both ferrous and non-ferrous.",
          "Auto-balancing with push-button operation.",
          "Highly sensitive and auto-tunes to every application."
        ],
        "Specifications": [
          "Detection Range: Minimum Pin from 1\" distance.",
          "Material: Non-Breakable/Scratch-Proof ABS Material",
          "Battery: Rechargeable 1.2volt × 4 600mAh \"AAA™ Eveready Ni-MH battery or 9-volt 170 mAh Ni-MH Rechargeable Battery",
          "Battery Backup: 40 hours",
          "Weight: 290g",
          "Length: 14.76\" (375 mm)",
          "Width: 3.03\" (77 mm)",
          "Thickness: 1.37\" (35 mm)",
          "Operating Temp: 15 to +45 degrees Celsius"
        ],
        "AdditionalInformation": "Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img9
      },
      {"id":"10",
        "ProductName": "Hand Held Metal Detector (MODEL Met scan MD-01 Ultra Slim)",
        "Price": "2,750",
        "Features": [
          "Designed for law enforcement security and policing.",
          "Detects all metals, both ferrous and non-ferrous.",
          "Auto-balancing with push-button operation.",
          "Highly sensitive and auto-tunes to every application.",
          "Working temperature: -10°C to 50°C"
        ],
        "Specifications": [
          "Length: 390 mm",
          "Width: 50 mm (Metal Body), 95 mm (Search Head)",
          "Height: 25 mm",
          "Sensitivity: Paper Pin (1cm)",
          "Battery: 400g (Metal Body), 260g (ABS Body)",
          "Rechargeable 9-volt 210 mAh Ni-MH battery.",
          "Battery Backup: 40 hours"
        ],
        "AdditionalInformation": "Warranty details or any other optional accessories can be provided by the manufacturer or seller."
        ,
        "imageUrl":img10
      },
      {"id":"11",
        "ProductName": "Hand Held Metal Detector (MODEL Excel MD-02)",
        "Price": "3,500",
        "Features": [
          "Used for safety purposes in airports, banks, malls, and other public places.",
          "Easy to handle and fast detection.",
          "Detects:",
          "- Pistol, 25 cal. at 8\" (200 mm)",
          "- Mini Revolver, 22 cal. at 6\" (150 mm)",
          "- Razor Blade at 2\" (50 mm)",
          "- Hat Pin at 1\" (25 mm)"
        ],
        "Specifications": [
          "Material: High Impact-resistant ABS case.",
          "Battery life of over 80 hours with a 9-volt rechargeable Ni-MH battery.",
          "Battery life of over 300 hours with a 9-volt Duracell alkaline battery."
        ],
        "AdditionalInformation": "This handheld metal detector is designed for security and safety purposes in various public places, providing efficient and reliable metal detection. Warranty details or any other optional accessories can be provided by the manufacturer or seller."
        ,
        "imageUrl":img11
      },
      {"id":"12",
        "ProductName": "Under Vehicle Search Mirror (Model Extension Type)",
        "Price": "3,500",
        "Features": [
          "An excellent tool for security personnel and bomb disposal squads.",
          "Ideal for under vehicle and table search operations.",
          "Helps locate explosive hideouts without physically approaching.",
          "Lightweight design for easy handling.",
          "Offers distinct vision for efficient searching.",
          "Maintenance-free.",
          "Easy to understand and operate."
        ],
        "Specifications": [],
        "AdditionalInformation": "This under vehicle search mirror is designed to provide security personnel and bomb disposal squads with a reliable tool for locating hidden threats and explosive devices without the need for physical approach. Warranty details or any other optional accessories can be provided by the manufacturer or seller."
        ,
        "imageUrl":img12
      },
      {"id":"13",
        "ProductName": "DOLPHIN52 Reflective Safety Jackets (Free Size)",
        "Price": "525",
        "Features": [
          "Reflective Safety Jackets come in a free size for versatile use.",
          "Widely used internationally by security personnel, factories, industries, roadworks, construction sites, traffic signals, airports, and many other purposes."
        ],
        "Specifications": [],
        "AdditionalInformation": "These reflective safety jackets are essential for enhancing visibility and safety in various industries and situations. They are a valuable tool for ensuring the safety of workers and individuals in low-light conditions. Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img13
      },
      {"id":"14",
        "ProductName": "Rechargeable Torch Light",
        "Price": "375",
        "Features": [
          "Rechargeable torch light for convenient and sustainable use."
        ],
        "Specifications": [],
        "AdditionalInformation": "This rechargeable torch light is a reliable source of illumination that can be recharged, making it a cost-effective and eco-friendly choice for various applications. Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img14
      },
      {"id":"15",
        "ProductName": "Search Light (Model Brita Light)",
        "Price": "5,000",
        "Features": [
          "Range: Up to 1KM (Approximate)",
          "SMPS inbuilt charger working from 90V to 270V",
          "Special dimming feature for indoor use",
          "Emergency lighting effect for indoor use",
          "Auto flashing feature with variable on/off time",
          "Advanced low battery status indication provided with long delayed audio beep."
        ],
        "Specifications": [],
        "AdditionalInformation": "This searchlight, model Brita Light, offers a powerful and versatile lighting solution with a long-range capability of up to 1 kilometer. It comes with various features, including dimming, emergency lighting, and low battery status indication, making it suitable for a range of applications. Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img15
      },
      {"id":"16",
        "ProductName": "10W Handheld LED Search Light",
        "Price": "5,000",
        "Features": [
          "Made of 'Unbreakable' ABS Body, designed to withstand the roughest conditions and usage.",
          "High-power 10W LED long-beam light that can cover a distance of up to 1000 meters.",
          "Operating Range of up to 1.2 Kilometers.",
          "5 Brightness Modes: SUPER STRONG-STRONG-HIGH-SOS-FLASHER.",
          "10W High Power CREE LED, producing a super bright light of 850 LUMENS.",
          "Water Resistant and strong ABS Body.",
          "Exclusive body design.",
          "Specially designed handle for use in different positions.",
          "Swivel Stand for ground placement at different angles.",
          "Soft Touch Side Switch for all operations.",
          "Big Parabolic Reflector making a long and very bright beam of light.",
          "Adjustable Shoulder Strap for easy carry."
        ],
        "Specifications": [
          "Battery: 3.7V, 6000mAh Lithium-Ion Battery.",
          "3-LED Battery Charging Indicator.",
          "Operation Time of Up to 10 hours.",
          "Dual Charging: AC/DC Wall Charger or 12V DC Car Plug Charger (both included)."
        ],
        "AdditionalInformation": "This 10W Handheld LED Search Light is designed to provide a powerful and reliable source of illumination for various applications. It's built to withstand tough conditions and offers a long-range capability of up to 1.2 kilometers. Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img16
      },
      {"id":"17",
        "ProductName": "Polycarbonate Baton 50CM",
        "Price": "200",
        "Features": [
          "Lightweight but strong construction.",
          "Has excellent mechanical strength.",
          "U.V. resistant.",
          "Comfortable to use with an adjustable strap.",
          "Provides excellent protection against public mobs.",
          "Scratch, fire, and water-resistant (not waterproof).",
          "Durable with a long lifespan.",
          "Available in different sizes for various applications."
        ],
        "Specifications": [],
        "AdditionalInformation": "These polycarbonate batons are designed to be lightweight yet strong, making them suitable for security and crowd control purposes. They offer durability, resistance to various elements, and are available in different sizes to meet specific needs. Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img17
      },
      {"id":"18",
        "ProductName": "Polycarbonate Baton 100CM",
        "Price": "300",
        "Features": [
          "Lightweight but strong construction.",
          "Has excellent mechanical strength.",
          "U.V. resistant.",
          "Comfortable to use with an adjustable strap.",
          "Provides excellent protection against public mobs.",
          "Scratch, fire, and water-resistant (not waterproof).",
          "Durable with a long lifespan.",
          "Available in different sizes for various applications."
        ],
        "Specifications": [],
        "AdditionalInformation": "These polycarbonate batons are designed to be lightweight yet strong, making them suitable for security and crowd control purposes. They offer durability, resistance to various elements, and are available in different sizes to meet specific needs. Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img17
      },
      {"id":"19",
        "ProductName": "Polycarbonate Shield",
        "Price": "2,500",
        "Features": [
          "Lightweight design for ease of use.",
          "High impact resistance, providing excellent protection against brick batting, stone pelting, iron rod, and cane attacks.",
          "High structural strength through thoughtful design.",
          "Transparent, allowing for visibility while providing protection.",
          "Ergonomic shoulder straps for comfortable carrying.",
          "Sturdy and comfortable arm grips."
        ],
        "Specifications": [],
        "AdditionalInformation": "This polycarbonate shield is designed to offer a lightweight yet highly protective solution for security personnel and crowd control purposes. It is engineered to provide excellent protection while being comfortable to carry and use. Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img18
      },
      {"id":"20",
        "ProductName": "Helmet (Level III Bulletproof)",
        "Price": "1,500",
        "Features": [
          "Level III Bulletproof helmet for enhanced protection.",
          "Made from armored fabric lamination to minimize weight for comfortable wearing.",
          "Provides protection against small firearms, including handguns up to 9mm pistol and revolvers.",
          "Note: Visor is not bulletproof."
        ],
        "Specifications": [],
        "AdditionalInformation": "This Level III Bulletproof helmet is designed to offer protection against small firearms while maintaining a comfortable and lightweight design. Please note that the visor is not bulletproof. Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img19
      },
      {"id":"21",
        "ProductName": "Alcohol Tester (Breath Alcohol Tester - AT-6000)",
        "Price": "1,500",
        "Features": [
          "Advanced Flat-Surfaced alcohol sensor for accurate testing.",
          "Quick response time.",
          "Smart MCU control for efficient operation.",
          "Direct testing process with LCD indication.",
          "Digital LCD display with light blue backup for easy reading.",
          "Portable and fashionable design.",
          "Audio warning beyond pre-set alcohol limit.",
          "Sensor Fault self-checking feature.",
          "Battery-saving design with low voltage indication.",
          "Applicable to driving and dangerous work environments."
        ],
        "Specifications": [],
        "AdditionalInformation": "The Alcohol Tester AT-6000 is designed to provide a quick and reliable way to measure alcohol levels in the breath. It comes with batteries and a straw for convenient use. Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img20
      },
      {"id":"22",
        "ProductName": "Alcohol Tester (Model T01)",
        "Price": "1,500",
        "Features": [
          "Portable exhaled gas alcohol content detector.",
          "Core component: Semiconductor sensor with high sensitivity, high stability, and convenient calibration.",
          "Liquid crystal display for easy reading."
        ],
        "Specifications": [
          "Size: L100mm x W28mm x H16mm",
          "Product Material: PC",
          "Charging input: 5V1A",
          "Battery capacity: 200mAh",
          "Measuring range: 0-0.19% BAC (0-1.9g/l)"
        ],
        "AdditionalInformation": "The Alcohol Tester Model T01 is a portable and convenient device for measuring alcohol content in exhaled breath. It features a semiconductor sensor and a liquid crystal display for accurate and easy-to-read results. Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img21
      },
      {"id":"23",
        "ProductName": "Alcohol Tester (Model T03)",
        "Price": "1,200",
        "Features": [],
        "Specifications": [
          "Product Material: PC",
          "Charging Input: DC 3.7V",
          "Battery Capacity: 200mAh"
        ],
        "AdditionalInformation": "The Alcohol Tester Model T03 is designed for measuring alcohol content. It features a PC material construction, DC 3.7V charging input, and a 200mAh battery capacity. Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img22
      },
      {"id":"24",
        "ProductName": "Portable Lamp (Search Light)",
        "Price": "500",
        "Features": [],
        "Specifications": [
          "Product Size: 173mm x 85mm x 110mm",
          "Product Weight: 350g",
          "Product Wick: LED strong light wick",
          "Charging Mode: Micro USB/USB interface",
          "High-capacity lithium battery",
          "Life-grade waterproof performance"
        ],
        "AdditionalInformation": "This portable lamp, also functioning as a searchlight, is designed for convenient and versatile illumination. It features an LED strong light wick, multiple charging options, a high-capacity lithium battery, and waterproof capabilities suitable for various outdoor activities. Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img23
      },
      {"id":"25",
        "ProductName": "Multi-functional Solar Portable Lamp",
        "Price": "1,000",
        "Features": [
          "Solar charging capability for eco-friendly power source.",
          "Long shot by strong light for extended illumination.",
          "Foldable design with double strong light.",
          "Emergency power bank function for charging devices.",
          "LED wick for efficient lighting.",
          "USB Charging Port for added convenience.",
          "Waterproof switch for durability.",
          "Side lamp illumination for versatility.",
          "Convenient hook for easy carrying and hanging."
        ],
        "Specifications": [],
        "AdditionalInformation": "This multi-functional solar portable lamp is a versatile lighting solution that can also serve as an emergency power bank. It's designed for various outdoor and emergency situations, providing reliable illumination and device charging. Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img24
      },
      {"id":"26",
        "ProductName": "BAOFENG Portable Two-Way Radio BF-888S (Walkie Talkie)",
        "Price":"1,500",
        "Features": [
          "Two-way portable transceiver for reliable, clear, and efficient communication.",
          "Best stability and great reliability, suitable for communication within buildings, shopping malls, and other noisy outdoor workplaces.",
          "Frequency range: 400-470MHz",
          "Channel capacity: 16",
          "Operated Voltage: 3.7V",
          "Weight: 150g",
          "Noise reduction circuit for improved audio quality.",
          "Emergency alarm call feature.",
          "Scrambler for secure communication.",
          "High-capacity Li-ion Battery for extended use.",
          "High illumination flashlight for added functionality.",
          "Comes with Antenna, Handstrap, Charger, Battery, Belt Clip, and User's Manual"
        ],
        "Specifications": [],
        "AdditionalInformation": "The BAOFENG BF-888S is a reliable two-way radio (walkie-talkie) designed for various communication needs. It offers noise reduction, emergency features, and a high-capacity battery for extended use. It is available both as individual pieces and in a box containing two units. Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img25
      },
      {"id":"27",
        "ProductName": "Plastic Cone Barricades with Rubber Base (Road Safety Cones)",
        "Price": "500",
        "Features": [],
        "Specifications": [],
        "AdditionalInformation": "These plastic cone barricades with rubber bases are designed for road safety and traffic management. They are ideal for indicating construction zones, diverting traffic, and ensuring safety on the road. Multiple units can be purchased as needed for specific traffic control requirements. Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img27
      },
      {"id":"28",
        "ProductName": "Handheld Megaphone (Portable Loudspeaker)",
        "Price": "1,000",
        "Features": [],
        "Specifications": [],
        "AdditionalInformation": "This handheld megaphone is a portable loudspeaker designed for various applications such as public announcements, crowd control, and event management. It is an essential tool for making your voice heard in large gatherings and outdoor events. Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img30
      },
      {"id":"29",
        "ProductName": "Unbreakable Convex Traffic Mirror",
        "Price": "2,500",
        "Features": [
          "The traffic mirror broadens the view of roads and streets.",
          "Comes with a protective film coating that makes the mirror scratch-resistant, break-resistant, and tear-proof.",
          "Can be used to increase safety in driveways, parking zones, pedestrian zones, and roads.",
          "Size: 24 inches"
        ],
        "Specifications": [],
        "AdditionalInformation": "This unbreakable convex traffic mirror is designed to enhance safety by providing a wider view of roads and streets. It is highly durable and resistant to damage, making it suitable for various outdoor applications. Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img28
      },
      {"id":"30",
        "ProductName": "Heavy Duty Anti-theft Car Wheel Lock",
        "Price": "2,000",
        "Features": [
          "The ultimate heavy-duty anti-theft solution for cars.",
          "Designed to completely immobilize a wheel and thwart any attempts at rotation or turning.",
          "Light-weight and user-friendly."
        ],
        "Specifications": [
          "Material: Cold rolled MS Steel",
          "Dimension: 480mm x 250mm",
          "Tire size for clamping: 7\" to 11\"",
          "Product Weight: 2 kg",
          "Keys: 3 pcs"
        ],
        "AdditionalInformation": "This heavy-duty anti-theft car wheel lock provides enhanced security by immobilizing the car's wheel, preventing it from being moved or stolen. It is designed to be user-friendly and comes with three keys for convenience. Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img29
      },
      {"id":"31",
        "ProductName": "Multifunctional LED Traffic Baton Rechargeable",
        "Price":  "1,000",
        "Features": [
          "Independent power switches and prompt lamp.",
          "New USB charging interface for rechargeable version.",
          "Comes with a magnet and sling for versatile use.",
          "High brightness LED for long-lasting illumination.",
          "Waterproof design, suitable for use on rainy days.",
          "High reflective film inside for added visibility.",
          "Includes a 120DB loud whistle."
        ],
        "Specifications": [],
        "AdditionalInformation": "The Multifunctional LED Traffic Baton is a versatile tool designed for traffic control and safety. It is available in both non-rechargeable and rechargeable versions to suit different preferences. The rechargeable version features a USB charging interface for convenience. Warranty details or any other optional accessories can be provided by the manufacturer or seller.",
        "imageUrl":img26,
      }
      
      
];

export default Products;